import expressions from 'angular-expressions';

export const CalcMixin = {
  data() {
    return {
      calcError: '',
      origCalcType: null,
      inCalcMode: false
    };
  },
  methods: {
    setupCalc($el) {
      if ($el.$el) {
        $el = $el.$el;
      }
      if ('INPUT' !== $el.tagName) {
        $el = $el.querySelector('input');
      }

      $el.addEventListener('change', () => {
        if (!$el.value.startsWith('=')) {
          this.inCalcMode = false;
          $el.type = this.origCalcType;
        }
      });
      $el.addEventListener('keydown', (event) => {
        if (this.inCalcMode) {
          event.stopPropagation();
        }
        if (event.key === 'Enter' || event.key === 'Tab') {
          this.recalc($el);
        } else
        if (event.key === '=') {
          event.preventDefault();
          event.stopPropagation();
          this.origCalcType = $el.type;
          $el.type = 'text';
          this.calcError = '';
          this.inCalcMode = true;
          this.$nextTick(() => {
            $el.value = '=';
          });
        }
      });
      $el.addEventListener('focus', () => {
        this.inCalcMode = $el.value.startsWith('=');
      });
    },
    recalc($el) {
      if (!this.inCalcMode) {
        return;
      }
      if (!$el.value.startsWith('=')) {
        return;
      }

      try {
        const formula = $el.value.substr(1).replace(/,/g, '.');
        const evaluate = expressions.compile(formula);
        this.$nextTick(() => {
          $el.value = evaluate();
          $el.setAttribute('value', $el.value);
          this.innerValue = $el.value;
          this.unmaskedValue = $el.value;
          this.inCalcMode = false;
          $el.type = this.origCalcType;
          this.calcError = '';
        });
      } catch (err) {
        const idx = err.message.indexOf('Syntax Error: ');
        if (idx > -1) {
          const msg = err.message.substring(idx + 'Syntax Error: '.length);
          this.calcError = msg;
        } else {
          console.error(err.message);
        }
      }
    }
  }
};
