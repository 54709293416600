<template>
  <ValidationProvider v-slot="{ errors, valid }" :vid="vid" :name="$attrs.name || $attrs.label" :rules="rules">
    <ControlField grouped :horizontal="horizontal" v-bind="$attrs" :type="{ 'is-danger': errors[0], 'is-success': verified }" :custom-class="verified ? 'is-verified' : ''">
      <div class="control expanded" v-if="mask === 'vat_number'">
        <imask-input
          v-model="unmaskedValue"
          :mask="'0000.000.000'"
          :disabled="$attrs.disabled"
          :lazy="false"
          :unmask="true"
          class="input"
          @complete="valueChanged"
          @blur="valueChanged"
        />
      </div>
      <div class="control expanded" v-else-if="mask === 'person_number'">
        <imask-input
          v-model="unmaskedValue"
          :mask="'00.00.00-000.00'"
          :disabled="$attrs.disabled"
          :lazy="false"
          :unmask="true"
          class="input"
          @complete="valueChanged"
          @blur="valueChanged"
        />
      </div>
      <o-input v-else :value="innerValue" @input="onInput" v-bind="calcAttrs" @blur="onBlur" expanded ref="calcInput" />
      <slot class="control"></slot>
    </ControlField>
    <p class="help is-danger">{{ calcError || errors[0] }}</p>
  </ValidationProvider>
</template>

<script>
import { IMaskComponent } from 'vue-imask';
import { CalcMixin } from '@/components/form/CalcMixin';
import { debounce } from 'lodash';

export default {
  components: {
    'imask-input': IMaskComponent
  },
  mixins: [CalcMixin],
  props: {
    mask: {
      type: String,
      default: ''
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    vid: {
      type: String,
      default: ''
    },
    rules: {
      type: [Object, String],
      default: ''
    },
    // must be included in props
    value: {
      type: null,
      default: null
    },
    verified: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    innerValue: '',
    unmaskedValue: '',
    debouncingValue: undefined
  }),
  watch: {
    value(newVal) {
      this.unmaskedValue = newVal;
      this.innerValue = newVal;
      if (this.mask === 'vat_number' && this.unmaskedValue.length === 9) {
        this.unmaskedValue = '0' + this.unmaskedValue;
      }
    }
  },
  created() {
    this.onInputDebounced = debounce(function( val) {
      this.$emit('input', val);
      this.debouncingValue = undefined;
    }, 800);

    if (this.value) {
      this.unmaskedValue = this.value;
      this.innerValue = this.value;
      if (this.mask === 'vat_number' && this.unmaskedValue.length === 9) {
        this.unmaskedValue = '0' + this.unmaskedValue;
      }
    }
  },
  mounted() {
    if ('number' !== this.$attrs.type) {
      return;
    }
    this.setupCalc(this.$refs['calcInput']);
  },
  computed: {
    calcType() {
      return this.inCalcMode ? 'text' : this.$attrs.type;
    },
    calcAttrs() {
      return Object.assign({}, this.$attrs,  { type: this.calcType });
    }
  },
  methods: {
    onBlur() {
      if ('undefined' !== typeof this.debouncingValue) {
        this.$emit('input', this.debouncingValue);
        this.debouncingValue = undefined;
      }
    },
    onInput(val) {
      this.debouncingValue = val;
      this.onInputDebounced(val);
    },
    valueChanged() {
      let value = this.unmaskedValue;
      if (this.mask === 'vat_number' && value.length === 9) {
        value = '0' + value;
      }
      this.$emit('input', value);
    }
  }
};
</script>

<style lang="scss" scoped>
.help {
  min-height: 17px;
  margin-bottom: 6px;
}

.field {
  margin-bottom: 0;
}
</style>
